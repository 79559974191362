import React, { useState } from 'react';
import './About.css';
import aboutImage from '../../../images/left-image.png';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const About = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <section className="section my-5" id="About">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <img
              src={aboutImage}
              className="rounded img-fluid d-block mx-auto"
              alt="about"
            />
          </div>
          <div className="right-text col-lg-6 col-md-12 col-sm-12 mobile-top-fix pl-5">
            <div className="left-heading">
              <h2>Take your application to the next level</h2>
            </div>
            <div className="left-text">
              <p>
                Want even more tools for your job application? Register for a
                free account so you can produce multiple resumes
                online, have access to more design features, or create a longer
                curriculum vitae (CV). You can even test the Premium features
                first for free.
              </p>
              <button className="main-button mt-3">
                <Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>
                  Discover More
                </Link>
              </button>
            </div>
          </div>
        </div>

        <button
          className="btn btn-link position-fixed top-0 end-0 p-3"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {menuVisible && (
          <div className="dropdown-menu position-fixed top-0 end-0 mt-5 p-3 bg-white shadow rounded">
            <Link to="/" className="dropdown-item">Home</Link>
            <Link to="/about" className="dropdown-item">About Us</Link>
            <Link to="/services" className="dropdown-item">Services</Link>
            <Link to="/contact" className="dropdown-item">Contact</Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default About;
