import React from 'react';
import './Features.css';
import icon1 from '../../../images/about-icon-01.png';
import icon2 from '../../../images/about-icon-02.png';
import icon3 from '../../../images/about-icon-03.png';
import crestImage from '../../../images/slider-icon123.png';
import featuresImage from '../../../images/right-image.png';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
  
const Features = () => {
  return (
    <section className="section" id="Features">
      {/* Features Section */}
      <div className="container">
        <div className="row">
          <div className="left-text col-lg-7 col-md-12 col-sm-12 mobile-bottom-fix">
            <div className="my-5">
              <h2>Our Resume Builder Features</h2>
              <p>
                A creative, professional layout can grab a recruiter's attention. Our resume layout optimizer makes sure all your content is aligned and organized so your resume looks like a work of art.
              </p>
            </div>
            <ul>
              <li className='d-flex'>
                <img src={icon1} className='feature-icon' alt="feature-icon1" />
                <div className="text">
                  <h6>Live Feedback to Improve Your Content</h6>
                  <p>
                    If you aren't sure how to write a resume, don't worry. Our content optimizer analyzes your resume and gives you suggested revisions to increase the quality of your content.
                  </p>
                </div>
              </li>
              <li className="my-5 d-flex">
                <img src={icon2} className='feature-icon' alt="feature-icon2" />
                <div className="text">
                  <h6>Free Resume Templates and Cover Letter</h6>
                  <p>
                    Not only do you get to build your resume for free, you also get access to successful resume samples and cover letter examples that you can use for guidance.
                  </p>
                </div>
              </li>
              <li className='d-flex'>
                <img src={icon3} className='feature-icon' alt="feature-icon3" />
                <div className="text">
                  <h6>Templates Suited to Your Exact Needs</h6>
                  <p>
                    Let our free resume templates and cover letter templates do the work for you. Choose from a selection of recruiter-approved layout designs for different job types.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12">
            <img src={crestImage} className="rounded img-fluid d-block mx-auto" alt="Features Crest" />
            <img src={featuresImage} className="rounded img-fluid d-block mx-auto" alt="Features" />
          </div>
        </div>

        {/* Menu Icon and Dropdown */}
        <div className="menu-icon">
          <Dropdown>
            <Dropdown.Toggle variant="link" bsPrefix="custom-dropdown-toggle">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={RouterLink} to="/">Home</Dropdown.Item>
              <Dropdown.Item as={RouterLink} to="/about">About Us</Dropdown.Item>
              <Dropdown.Item as={RouterLink} to="/services">Services</Dropdown.Item>
              <Dropdown.Item as={RouterLink} to="/contact">Contact</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </section>
  );
};

export default Features;
