import React, { useState } from 'react';
import './Header.css';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useAuth } from '../../Authentication/useAuth';
import userPhoto from '../../../images/User.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const auth = useAuth();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white py-2 sticky-top">
      <RouterLink className="navbar-brand text-info" to="/">Resume Builder</RouterLink>
      <button
        className="navbar-toggler"
        type="button"
        onClick={toggleMenu}
        aria-expanded={isMenuOpen}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarTogglerDemo02">
        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
          <li className="nav-item">
            <ScrollLink
              to="Hero"
              smooth={true}
              duration={500}
              className="nav-link"
              style={{ cursor: 'pointer' }}
            >
              Services
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink
              to="Features"
              smooth={true}
              duration={500}
              className="nav-link"
              style={{ cursor: 'pointer' }}
            >
              Features
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink
              to="Templates"
              smooth={true}
              duration={500}
              className="nav-link"
              style={{ cursor: 'pointer' }}
            >
              Templates
            </ScrollLink>
          </li>
          <li className="nav-item">
            <ScrollLink
              to="Contact"
              smooth={true}
              duration={500}
              className="nav-link"
              style={{ cursor: 'pointer' }}
            >
              Contact Us
            </ScrollLink>
          </li>
        </ul>

        <ul className="navbar-nav align-items-center">
          <li className="nav-item">
            {auth.user ? (
              <RouterLink to="/" className="nav-link">
                {auth.user.displayName}
                <img
                  className="ml-3"
                  src={auth.user.photoURL ? auth.user.photoURL : userPhoto}
                  width="35px"
                  alt=""
                />
              </RouterLink>
            ) : (
              <RouterLink to="/login" className="nav-link">Login</RouterLink>
            )}
          </li>

          <li className="nav-item">
            {auth.user ? (
              <RouterLink to="/" className="nav-link">
                <button
                  onClick={() => { auth.signOut(); }}
                  className="btn btn-primary"
                >
                  Sign Out
                </button>
              </RouterLink>
            ) : (
              <RouterLink to="/login" className="nav-link">
                <button className="btn btn-primary">Sign Up</button>
              </RouterLink>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
